<template>
    <div class="log public_wrap">
        <topnav active="log"></topnav>
        <topmenu active="log"></topmenu>

        <div class="public_width">
            <div class="log_box">
                <ul class="log_cate" :class="!more? 'd-flex':''">
                    <li :class="active_id == 0 ? 'active':''" @click="changeCate(0)"><span class="cate_txt">全部分类</span></li>
                    <li :class="active_id == v.id ? 'active':''" v-for="(v,k) in classify" :key="k" v-show="more||(!more && k < 7)" @click="changeCate(v.id)"><span class="cate_txt">{{v.name}}</span></li>
                    <li class="more" v-if="!more && classify.length > 7"><span class="more_txt"  @click="changeMore">更多</span></li>
                    <li class="more" v-if="more"><span class="more_txt" @click="changeLess" >收起</span></li>
                </ul>
                
                <div class="log_table">
                    <div class="log_wrap" :class="active_id == item.id ? 'active':''"  v-for="(item) in classify" :key="item.id">
                        <h3 :ref="active_id == item.id ? 'log':''"  @click="to_product(item)">{{item.name}}</h3>
                        <div class="log_body">
                            <p v-for="(v) in item.children" :key="v.id" @click="to_product(item,v)">{{v.name}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <foot />
        <toolbar />
    </div>
</template>

<script>
import topnav from "@/components/Nav.vue";
import topmenu from "@/components/Menu.vue";
import toolbar from "@/components/Toolbar.vue";
import { getData } from "@/api/user";
import foot from "@/components/Footer.vue";
import { reactive, toRefs, ref, nextTick } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { ElMessage } from "element-plus";

export default {
	name: "log",
	components: {
		topnav,
		topmenu,
		foot,
        toolbar
	},
	setup() {
		const store = useStore();
		const router = useRouter();
        const log = ref(null)
		store.commit("setCount");

		let state = reactive({
			userid: store.state.userId,
			classify:[],
			more: false,
			active_id : 0
		});

		let init = () => {
			state.menu_show = name === 'home'
			getData({
				url: `api/home/category_list`,
				data: {},
			}).then((res) => {
				let data = res.data;
				if (data.code === 200) {
					state.classify = data.data.list
				}else {
					ElMessage.error(data.data.msg);
				}
			});
		}
		init()

		let changeMore = () => {
			state.more = true
		}

        let changeLess = () =>{
            state.more = false
        }

		let changeCate = (id) => {
			state.active_id = id
            nextTick(() =>{
                let top = id ? log.value.offsetTop -208 :0
                window.scrollTo(100,top)
            })
		}

		let to_product = (item,v) => {
            if(v && v.name !='') {
                router.push({
                path: '/product',
                query:{
                    first:item.name,
                    second: v.name,
                    first_id: item.id,
                    second_id: v.id
                }
                })
            }else {
                router.push({
                path: '/product',
                query:{
                    first:item.name,
                    first_id: item.id,
                }
                })
            }
            window.scrollTo(100,0)
        }


		return {
            log,
			...toRefs(state),
			changeMore,
            changeLess,
			changeCate,
			to_product
		};
	},
};
</script>

<style lang="less" scoped>
.log {
    padding: 154px 0 440px;
}
.log_box {
    background:#fff;
    margin: 20px 0;
	padding: 30px;
}

.log_cate {
    li {
        display:inline-block;
        width: 132px;
        height: 40px;
        font-size: 13px;
        line-height: 18px;
        color: #333;
        text-align: center;
        line-height: 40px;
        margin:0 12px 12px 0;
        text-overflow:ellipsis;
        overflow:hidden;
        white-space: nowrap;
        cursor: pointer;
        .cate_txt {
            display: inline-block;
            height: 100%;
            width: 100%;
            background: #fff;
            border: 1px solid #707070;
        }
        .cate_txt:hover {
            color: #1C46B7;
            border: 1px solid #1C46B7;
        }
    }
    li.active {
        .cate_txt {
            background: #1C46B7;
            border: 1px solid #1C46B7;
            color: #FFF;
        }
    }
    .more {
        text-align:left;
    }
    .more_txt {
        color: #1C46B7;
        text-decoration: underline;
        cursor: pointer;
        text-align:left;
        margin-left: 12px;
    }
}
.log_table {
	margin-top: 30px;
	.log_wrap {
		border: 1px solid #EAEAEA;
        margin-bottom: 20px;
	}
    .log_wrap:hover {
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    }
    .log_wrap.active {
        animation: ani 5000ms;
    }

    @keyframes ani {
        0% {border: 1px solid #1C46B7;}
        100%{border: 1px solid #EAEAEA;}
    }

	h3 {
        cursor: pointer;
		height: 40px;
		line-height: 40px;
		background: #EDEDED;
		padding-left: 28px;
		font-size: 13px;
		color: #333333;
		font-weight: 400;
	}
	.log_body {
		padding: 20px 20px 20px 28px;
		font-size: 13px;
		line-height: 18px;
		color: #1C46B7;
		p {
			display: inline-block;
			min-width:10%;
			margin-bottom: 20px;
			cursor: pointer;
            padding: 5px 0;
            margin-right: 45px;
		}
        p:hover {
            color: #052579;
            text-decoration: underline;
        }
	}
}
</style>
